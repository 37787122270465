import type { ComponentType } from "react"

export function withFloatingEffect(Component): ComponentType {
    return (props) => {
        const intensity = props.intensity || "medium" // Default to medium if not specified
        const animations = {
            low: {
                translateY: [-2, 2, -2],
                transition: {
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                },
            },
            medium: {
                translateY: [-5, 5, -5],
                transition: {
                    duration: 2.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                },
            },
            high: {
                translateY: [-10, 10, -10],
                transition: {
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                },
            },
        }

        return <Component {...props} animate={animations[intensity]} />
    }
}
